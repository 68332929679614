import {
  applyBarrier,
  concurrency,
  createBarrier,
  createQuery,
  keepFresh,
} from '@farfetched/core'
import type { EventPayload } from 'effector'
import { combine, createEvent, createStore, sample } from 'effector'
import { createAction } from 'effector-action'
import { createGate } from 'effector-react'
import { not } from 'patronum'
import { getChannelAccessesBaseFx } from '~/api/access-service.effects'
import { createAnalyticsServiceEffect } from '~/api/analytics'
import { METRICS } from '~/api/constants'
import { getChannelByIdBaseFx } from '~/api/manage.effects'
import { APP_START_DATE } from '~/constants'
import { toggleFavoriteChannelMutation } from '~/features/channel/toggle-favorite/model'
import { userBarrier } from '~/user/barrier'
import type { PageParams } from './types'

export const ChannelDashboardGate = createGate<{
  params: PageParams
}>()
const $channelParams = createStore<PageParams | null>(null)
export const $channelId = $channelParams.map(params =>
  params ? +params.channel_id : null,
)

export const channelChanged = createEvent()

// $channelId.watch(channelId => console.log('ID Канала: ', channelId))
// channelChanged.watch(() => console.log('Канал изменен'))

sample({
  clock: ChannelDashboardGate.open,
  filter: ChannelDashboardGate.status,
  fn: ({ params }) => params,
  target: createAction({
    source: { prevChannelId: $channelId },
    target: { $channelParams, channelChanged },
    fn: (target, { prevChannelId }, params) => {
      target.$channelParams(params)

      if (prevChannelId !== null && prevChannelId !== +params.channel_id) {
        target.channelChanged()
      }
    },
  }),
})

// sample({
//   clock: ChannelDashboardGate.close,
//   filter: not(ChannelDashboardGate.status),
//   target: $channelParams.reinit,
// })

// ChannelDashboardGate.close.watch(console.log)

export const mainChannelQuery = createQuery({
  effect: getChannelByIdBaseFx,
})

applyBarrier(mainChannelQuery, {
  barrier: userBarrier,
})

concurrency(mainChannelQuery, {
  strategy: 'TAKE_LATEST',
})

const channelQueryForceRefresh = createEvent()

keepFresh(mainChannelQuery, {
  triggers: [channelQueryForceRefresh],
})

export const channelAccessesQuery = createQuery({
  effect: getChannelAccessesBaseFx,
})

applyBarrier(channelAccessesQuery, {
  barrier: userBarrier,
})

concurrency(channelAccessesQuery, {
  strategy: 'TAKE_LATEST',
})

export const channelSubscribersQuery = createQuery({
  effect: createAnalyticsServiceEffect({
    method: 'post',
    url: '/v2/base_metrics/subs/last_time',
  }),
})

applyBarrier(channelSubscribersQuery, {
  barrier: userBarrier,
})

concurrency(channelSubscribersQuery, {
  strategy: 'TAKE_LATEST',
})

export const $channelTotalSubscribers = combine(
  [channelSubscribersQuery.$data],
  ([data]) => {
    return data?.total ?? null
  },
)

const $channelMetrics = combine([channelAccessesQuery.$data], ([accesses]) => {
  if (!accesses) return []

  const metrics = accesses.tariff.metrics ?? []

  return metrics
})

export const $inviteLinksAvailable = $channelMetrics.map(metrics =>
  metrics.map(({ name }) => name).includes(METRICS.invite_links),
)

export const $isChannelLoaded = combine(
  [
    mainChannelQuery.$status,
    channelAccessesQuery.$status,
    channelSubscribersQuery.$status,
  ],
  ([mainStatus, accessesStatus, subscribersStatus]) => {
    return (
      (mainStatus === 'done' || mainStatus === 'fail') &&
      (accessesStatus === 'done' || accessesStatus === 'fail') &&
      (subscribersStatus === 'done' || subscribersStatus === 'fail')
    )
  },
)

sample({
  clock: $channelId,
  target: [
    mainChannelQuery.reset,
    channelAccessesQuery.reset,
    channelSubscribersQuery.reset,
  ],
})

sample({
  clock: $channelId,
  filter: Boolean,
  fn: channel_id =>
    ({
      params: {
        path: {
          channel_id: channel_id,
        },
      },
    }) satisfies EventPayload<typeof mainChannelQuery.refresh>,
  target: mainChannelQuery.refresh,
})

sample({
  clock: $channelId,
  filter: Boolean,
  fn: channel_id =>
    ({
      params: {
        query: {
          channels: [channel_id],
        },
      },
    }) satisfies EventPayload<typeof channelAccessesQuery.refresh>,
  target: channelAccessesQuery.refresh,
})

sample({
  clock: $channelId,
  filter: Boolean,
  fn: channel_id =>
    ({
      params: {
        query: {
          channel_id: channel_id,
        },
      },
    }) satisfies EventPayload<typeof channelSubscribersQuery.refresh>,
  target: channelSubscribersQuery.refresh,
})

sample({
  clock: toggleFavoriteChannelMutation.finished.success,
  target: channelQueryForceRefresh,
})

export const channelBarrier = createBarrier({
  active: not($isChannelLoaded),
})

export const $channelServiceRegisteredAtDate = combine(
  [mainChannelQuery.$data],
  ([data]) => {
    return data?.channel.created_at
      ? new Date(data.channel.created_at)
      : APP_START_DATE
  },
)
